import React from 'react';
import { Typography } from 'antd';
import { includes, isArray } from 'lodash';
import { $injector } from 'ngimport';

import { FormSubmissionData } from '../types/enquiry';
import { CdVerticalSpace } from '../../../shared/components/cd-vertical-space/CdVerticalSpace';
import { UibModal } from '../../services/EventPopupService';

export interface FormSubmissionViewerProps {
  submissionData: FormSubmissionData;
}

export const FormSubmissionViewer = (props: FormSubmissionViewerProps) => (
  <CdVerticalSpace size={0}>
    {props.submissionData?.map((section) => (
      <React.Fragment key={section?.label}>
        <Typography.Title level={3}>{section?.label}</Typography.Title>
        <CdVerticalSpace>
          {isArray(section?.value) &&
            section?.value?.map((value) => (
              <CdVerticalSpace size={0} key={value?.label || value?.value}>
                <Typography.Text type="secondary">
                  {value?.label}
                </Typography.Text>
                {isArray(value?.value)
                  ? value?.value.map((file) => <FileInForm file={file} />)
                  : value?.value}
                {value?.fileInfo && <FileInForm file={value} />}
              </CdVerticalSpace>
            ))}
        </CdVerticalSpace>
      </React.Fragment>
    ))}
  </CdVerticalSpace>
);

const FileInForm = (props: { file: any }) => (
  <Typography.Link onClick={() => openFilePreviewer(props.file)}>
    {props.file.title}
  </Typography.Link>
);

const openFilePreviewer = (item: any) => {
  const viewableDocuments = ['pdf', 'image', 'audio', 'video'];
  const $uibModal = $injector.get('$uibModal') as UibModal;
  const modalType = includes(viewableDocuments, item.fileInfo.filetype.type)
    ? 'document-viewer-modal'
    : 'document-download-modal';

  return $uibModal.open({
    component: 'cdDocumentViewer',
    windowClass: modalType,
    resolve: {
      item: () => item,
    },
  });
};
